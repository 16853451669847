const manifest = require('../package.json')
const configuration = {
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.test.plusadvance.apio.network',
  environment: process.env.REACT_APP_ENV || 'test',
  gtmId: process.env.REACT_APP_GTM_ID || '',
  intercomKey: process.env.REACT_APP_INTERCOM_SECRET_KEY || ''
}

export default configuration
