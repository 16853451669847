import { useEffect } from 'react'

const TawkChat = () => {
  useEffect(() => {
    // Evita di caricare lo script più volte
    if (window.Tawk_API) return

    const script = document.createElement('script')
    script.src = 'https://embed.tawk.to/58ef770df7bbaa72709c6129/default'
    script.async = true
    script.charset = 'UTF-8'
    script.setAttribute('crossorigin', '*')
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default TawkChat
