// src/hooks/useTawkAttributes.js
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ls from 'local-storage'

export const useTawkAttributes = () => {
  const currentCompany = useSelector(state => state.session.currentCompany)
  const user = ls.get('plsadv_user')

  useEffect(() => {

    if (!user || !user.email || !currentCompany || !currentCompany.name) {
      return
    }

    const {
      isBuyer,
      isSupplier,
      name,
      vatId
    } = currentCompany

    const rawEmail = user.email || ''
    const email = rawEmail.trim().toLowerCase()

    const isTempEmail = (email) => {
      return /@(yopmail\.com|mailinator\.com|tempmail\.|guerrillamail\.)$/i.test(email)
    }

    const safeEmail = isTempEmail(email) ? undefined : email

    const attributes = {
      ...(safeEmail && { email: safeEmail }),
      name,
      vat: vatId,
      issupplier: isSupplier,
      isabuyer: isBuyer
    }

    const setAttributes = () => {
      if (window.Tawk_API && typeof window.Tawk_API.setAttributes === 'function') {
        window.Tawk_API.setAttributes(attributes, (err) => {
          if (err) {
            console.error('[Tawk.to] ❌ Errore setAttributes:', err, attributes)
          }
        })
        return true
      }
      return false
    }

    const attributesSet = setAttributes()
    if (!attributesSet) {
      let attempts = 0
      const maxAttempts = 10
      const interval = setInterval(() => {
        attempts++
        if (setAttributes()) {
          clearInterval(interval)
        } else if (attempts >= maxAttempts) {
          console.warn('[Tawk.to] 🛑 Impossibile settare attributi dopo 10 tentativi')
          clearInterval(interval)
        }
      }, 500)

      return () => clearInterval(interval)
    }
  }, [user, currentCompany])
}
